import React from 'react';
import { string, bool, number, shape } from 'prop-types';
import { Button } from '@andes/button';
import Image from '../../../../commons/image';
import PricesValues from '../prices-values';

const LoyaltyBuyLevel6DesktopHeader = (props) => {
  const {
    title = '',
    pricing: { from = null, time = '', price = {}, currency: symbol = '' } = {},
    gradient_color = '',
    logo = '',
    benefits_description = '',
    action = {},
    is_evolution = false,
    currency_id = '',
    deviceType = '',
  } = props;

  const DEG_LOYALTY = gradient_color?.deg ?? '90deg';
  const FIRST_COLOR_LOYALTY = gradient_color?.first_color ?? '#a90f90';
  const PERCENTAGE_LOYALTY = gradient_color?.percentage ?? '55%';
  const SECOND_COLOR_LOYALTY = gradient_color?.second_color ?? '#0c1a51';

  return (
    <div
      className="loyalty-buylevel6__header"
      style={{
        '--deg_loyalty': DEG_LOYALTY,
        '--first_color_loyalty': FIRST_COLOR_LOYALTY,
        '--percentage_loyalty': PERCENTAGE_LOYALTY,
        '--second_color_loyalty': SECOND_COLOR_LOYALTY,
      }}
    >
      <div className="loyalty-buylevel6__header__title">
        {logo && (
          <Image
            {...logo}
            className="loyalty-buylevel6__header__title-img"
            lazyLoad
            alt={logo.alt}
          />
        )}
        <div className="loyalty-buylevel6__header__title">
          <span className="loyalty-buylevel6__header__title-text">{title}</span>
          {price && (
            <PricesValues
              price={price}
              from={from}
              time={time}
              is_evolution={is_evolution}
              currency_id={currency_id}
              symbol={symbol}
              deviceType={deviceType}
            />
          )}
        </div>
      </div>
      {action && (
        <div className="loyalty-buylevel6__action">
          <span className="loyalty-buylevel6__action-title">
            {benefits_description}
          </span>
          <Button
            className="loyalty-buylevel6__action-button"
            hierarchy="quiet"
            href={action.target}
            size="medium"
          >
            {action.label?.text}
          </Button>
        </div>
      )}
    </div>
  );
};

LoyaltyBuyLevel6DesktopHeader.propTypes = {
  title: string,
  pricing: shape({
    description: string,
    is_feature_on: bool,
    is_only_pill: bool,
    from: string,
    currency: string,
    time: string,
    price: shape({
      units: string,
      cents: number,
    }),
    is_pill_description_on: bool,
    show_divider: bool,
    from_label: string,
    pill_discount: shape({
      label: string,
    }),
  }),
  gradient_color: shape({
    deg: string,
    first_color: string,
    percentage: string,
    second_color: string,
  }),
  logo: shape({
    alt: string,
    src: string,
  }),
  is_evolution: bool,
  action: shape({
    target: string,
    label: shape({
      text: string,
    }),
  }),
  benefits_description: string,
  deviceType: string,
  currency_id: string,
};

export default LoyaltyBuyLevel6DesktopHeader;
