import React from 'react';
import classNames from 'classnames';
import { string, shape } from 'prop-types';
import { MoneyAmount, MoneyAmountCombo } from '@andes/money-amount';

const removeCurrency = (value) => value?.replace(/[^\d,.-]/g, '').trim();
const removeSlash = (text) => text?.replace('/', '');

const PricesValues = ({
  price = {},
  from = '',
  time = '',
  is_evolution = false,
  currency_id = '',
  symbol = '',
  deviceType = '',
}) => {
  const currentPriceProps = {
    className: classNames('loyalty-buylevel6__header__title-price--value', {
      'loyalty-buylevel6__header__title-price--value--evolution': is_evolution,
    }),
    currencyId: currency_id,
    symbol,
    weight: 'semibold',
    suffix: removeSlash(time),
    value: {
      cents: price?.cents,
      fraction: price?.units,
    },
    size: deviceType === 'mobile' ? 18 : 20,
    centsType: 'superscript',
  };

  return from ? (
    <MoneyAmountCombo
      className="loyalty-buylevel6__header__title-price"
      currentValue={currentPriceProps}
      previousValue={{
        className: 'loyalty-buylevel6__header__title-price--strike',
        currencyId: currency_id,
        symbol,
        value: { fraction: removeCurrency(from) },
        size: deviceType === 'mobile' ? 18 : 20,
      }}
    />
  ) : (
    <MoneyAmount {...currentPriceProps} />
  );
};

PricesValues.propTypes = {
  price: shape({
    cents: string,
    units: string,
  }),
  from: string,
  time: string,
  is_evolution: string,
  deviceType: string,
  currency_id: string,
  symbol: string,
};

export default PricesValues;
